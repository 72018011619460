import {
  ContainerPaddingValue,
  RichTextObject,
} from '@sprinklr/shared-lib/@types/entities';
import { Row as RowV3 } from './components/V3/types';

type CellData = {
  id: string;
  content: RichTextObject;
  additionalInfo?: string;
  icon?: { icon: string };
  image?: any;
};

export type Row = {
  id: string;
  cell: CellData[];
};

export type Props = (V1Props | V2Props) & {
  title: string;
  tableSubTitle: any;
  footerText?: any;
  iconsLegend?: IconLegend[];
  colorVariant?: TableColorVariant;
  topMargin?: ContainerPaddingValue;
  bottomMargin?: ContainerPaddingValue;
};

type V1Props = {
  variant?: 'V3';
  rows: RowV3[];
};

type V2Props = {
  title: string;
  variant?: 'V1' | 'V2';
  rows: Row[];
};

export type IconLegend = {
  title: string;
  icon: {
    icon: string;
  };
};

export enum TableColorVariant {
  DEFAULT = 'DEFAULT',
  LIGHT_PRIMARY = 'LIGHT_PRIMARY',
}
