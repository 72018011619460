import { ThemeUIStyleObject } from 'theme-ui';

export const getHeaderContainerStyles = ({ rowIdx, isActive }: { rowIdx: number; isActive: boolean }): ThemeUIStyleObject => {
  const isEvenRow = rowIdx % 2 === 0;
  const backgroundColor = isEvenRow ? '#303840' : '#F1F1F1';

  return {
    cursor: 'pointer',
    display: 'flex',
    backgroundColor,
    width: '50%',
    zIndex: isActive ? 1 : 'auto',
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    flexBasis: 0,
    paddingX: '24px',
    paddingY: '16px',
    flexGrow: 1,
    flexShrink: 0,
    borderBottom: isActive ? '3px solid #FAA21B' : `3px solid ${backgroundColor}`,
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const getHeaderCellStyles = ({ rowIdx, isActive }: { rowIdx: number; isActive: boolean }): ThemeUIStyleObject => {
  const isEvenRow = rowIdx % 2 === 0;
  return {
    display: 'flex',
    h6: {
      color: isEvenRow ? '#FAA21B' : '#828282',
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: '21px',
      marginBottom: '0px',
    },
  };
};

export const getColHeadingStyles = ({
  colIdx,
  activeRowIndex,
  totalColumns,
}: {
  colIdx: number;
  activeRowIndex: number;
  totalColumns: number;
}): ThemeUIStyleObject => {
  const isEvenRowActive = activeRowIndex % 2 === 0;
  const isFirstCol = colIdx === 0;
  const isLastCol = colIdx === totalColumns - 1;

  return {
    paddingTop: isFirstCol ? '0px' : '12px',
    display: 'flex',
    h6: {
      color: isEvenRowActive ? '#FFFFFF' : '#C2C2C2',
      marginBottom: '0px',
      fontSize: '17px',
      lineHeight: '21px',
      fontWeight: 400,
    },
  };
};

export const getColContentStyles = ({
  colIdx,
  activeRowIndex,
  totalColumns,
}: {
  colIdx: number;
  activeRowIndex: number;
  totalColumns: number;
}): ThemeUIStyleObject => {
  const isEvenRowActive = activeRowIndex % 2 === 0;
  const isFirstCol = colIdx === 0;
  const isLastCol = colIdx === totalColumns - 1;

  return {
    paddingBottom: isLastCol ? '0px' : '12px',
    display: 'flex',
    borderBottom: isLastCol ? 'none' : '1px solid #D4D4D4',
    h6: {
      color: isEvenRowActive ? '#FAA21B' : '#4B4B4B',
      marginBottom: '0px',
      fontSize: '17px',
      lineHeight: '21px',
      fontWeight: 400,
    },
  };
};
