import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';
import { getHeaderCellStyles, getRowCellStyles, getRootStyles } from './styles';
import { CellContent, CellWrapper } from './Cell';
import { Row } from './types';

const DesktopTable = ({ rows }: { rows: Row[] }): ReactElement => {
  const headerRows = rows[0];
  const dataRows = rows.slice(1) || [];

  const totalColumns = headerRows.cell.length;
  const totalRows = dataRows.length;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: 3,
        width: '100%',
        maxWidth: '80%',
        margin: 'auto',
        borderRadius: '12px',
      }}
    >
      <>
        <Box sx={{ display: 'flex' }}>
          {headerRows.cell.map((header, idx) => {
            return (
              <CellWrapper
                cellSx={getHeaderCellStyles({ cellIdx: idx })}
                rootSx={getRootStyles({
                  colIdx: idx,
                  rowIdx: 0,
                  isHeader: true,
                  totalRows,
                  totalColumns,
                })}
              >
                <CellContent cell={header} />
              </CellWrapper>
            );
          })}
        </Box>

        {dataRows.map((row, rowIdx) => {
          return (
            <Box sx={{ display: 'flex' }}>
              {row.cell.map((cell, colIdx) => {
                return (
                  <CellWrapper
                    cellSx={getRowCellStyles({
                      colIdx,
                      rowIdx,
                      totalRows,
                      totalColumns,
                    })}
                    rootSx={getRootStyles({
                      colIdx,
                      rowIdx,
                      totalRows,
                      totalColumns,
                      isHeader: false,
                    })}
                  >
                    <CellContent cell={cell} />
                  </CellWrapper>
                );
              })}
            </Box>
          );
        })}
      </>
    </Flex>
  );
};

export default DesktopTable;
