import { ThemeUIStyleObject } from 'theme-ui';

export const getHeaderCellStyles = ({ columnIndex }: { columnIndex: number }): ThemeUIStyleObject => {
  return {
    paddingX: ['12px', null, '20px'],
    paddingY: '10px',
    display: 'flex',
    alignItems: 'center',
    'p, h6': {
      marginBottom: '0px',
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: '600',
    },
  };
};

export const getRowCellStyles = ({
  columnIndex,
  rowIndex,
  totalRows,
}: {
  columnIndex: number;
  rowIndex: number;
  totalRows: number;
}): ThemeUIStyleObject => {
  const isMainColumn = columnIndex === 1;
  const isLastRow = rowIndex === totalRows - 1;
  const isFirstColumn = columnIndex === 0;

  return {
    paddingX: ['12px', null, '20px'],
    paddingTop: '12px',
    paddingBottom: isLastRow ? '35px' : '12px',
    display: 'flex',
    alignItems: isFirstColumn ? 'center' : 'flex-start',
    'p, h6': {
      marginBottom: '0px',
      color: isMainColumn ? 'white' : columnIndex === 0 ? 'black' : '#1E1E1E',
      fontSize: isFirstColumn ? '16px' : '14px',
      lineHeight: '20px',
      fontWeight: isFirstColumn ? '600' : '400',
    },
  };
};

export const getHeaderContainerStyles = ({ columnIndex }: { columnIndex: number }): ThemeUIStyleObject => {
  const isMainColumn = columnIndex === 1;
  const isFirstColumn = columnIndex === 0;

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: isFirstColumn ? 3 : 4,
    flexBasis: 0,
    backgroundColor: isMainColumn ? '#107EFF' : undefined,
    opacity: columnIndex === 0 ? 0 : 1,
  };
};

export const getRowContainerStyles = ({ columnIndex, rowIndex }: { columnIndex: number; rowIndex: number }): ThemeUIStyleObject => {
  const isMainColumn = columnIndex === 1;
  const isFirstColumn = columnIndex === 0;

  return {
    display: 'flex',
    flexGrow: isFirstColumn ? 3 : 4,
    flexBasis: 0,
    justifyContent: 'flex-start',
    backgroundColor: isMainColumn ? '#107EFF' : undefined,
  };
};

export const getIconStyles = ({ columnIndex }: { columnIndex: number }) => {
  let marginTop, height, width, color;
  switch (columnIndex) {
    case 0:
      color = '#0064FB';
      height = '16px';
      width = '16px';
      break;

    case 1:
      marginTop = '3px';
      break;

    default:
      marginTop = '3px';
      color = '#BCBCBC';
  }

  return {
    path: { fill: color },
    color,
    fill: color,
    marginTop,
    height,
    width,
  };
};
