import React, { CSSProperties } from 'react';
import { Box, Flex, ThemeUICSSObject } from 'theme-ui';
import { IconLegend } from '../types';
import IconComponent from './IconComponent';

const ICONS_LEGEND_STYLES: ThemeUICSSObject = {
  gap: '20px',
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
  m: '20px 20px 0 20px',
};

const ICON_LEGEND_STYLES: ThemeUICSSObject = {
  gap: '20px',
  alignItems: 'center',
};

const ICON_LEGEND_SPAN_STYLES: ThemeUICSSObject = {
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 400,
  color: 'text',
  maxWidth: '200px',
};

const SVG_STYLES: CSSProperties = { width: '32px', height: '32px' };

const IconsLegend = ({ icons }: { icons: IconLegend[] }) => {
  return (
    <Flex sx={ICONS_LEGEND_STYLES}>
      {icons.map(icon => (
        <IconLegendComponent key={icon.title} {...icon} />
      ))}
    </Flex>
  );
};

const IconLegendComponent = ({ title, icon }: IconLegend) => {
  return (
    <Flex sx={ICON_LEGEND_STYLES}>
      <IconComponent iconType={icon?.icon} style={SVG_STYLES} />
      <Box as="span" sx={ICON_LEGEND_SPAN_STYLES}>
        {title}
      </Box>
    </Flex>
  );
};

export default IconsLegend;
