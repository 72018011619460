import { ColorMode } from 'theme-ui';
import { TableColorVariant } from './types';

export const getColorMode = (variant: TableColorVariant): ColorMode => {
  switch (variant) {
    case TableColorVariant.LIGHT_PRIMARY:
      return {
        primary: '#E9F3FF',
        primarySvgBackground: 'transparent',
        nonPrimarySvgBackground: 'transparent',
      };
    case TableColorVariant.DEFAULT:
    default:
      return {
        primary: '#107EFF',
        primarySvgBackground: '#fff',
        nonPrimarySvgBackground: '#000',
      };
  }
};
