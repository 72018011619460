import React, { useState, ElementType } from 'react';

import { RichText } from '@sprinklr/shared-lib';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';

import {
  getHeaderCellStyles,
  getColHeadingStyles,
  getColContentStyles,
  getHeaderContainerStyles,
} from './styles';
import { Row } from '../../types';

const DataCell = ({
  content,
  cellSx,
  containerSx,
  onClick,
  icon,
  iconSx,
  as,
}: {
  content: Document;
  cellSx?: ThemeUIStyleObject;
  containerSx?: ThemeUIStyleObject;
  iconSx?: ThemeUIStyleObject;
  onClick?: () => void;
  icon?: { icon: string };
  as?: ElementType;
}) => {
  return (
    <Box sx={containerSx} as={as} onClick={onClick}>
      <Box sx={cellSx}>
        <Icon
          iconName={icon?.icon}
          iconSx={{
            height: '14px',
            width: '14px',
            flexShrink: 0,
            marginRight: '10px',
            ...iconSx,
          }}
        />
        <RichText richTextObject={content} />
      </Box>
    </Box>
  );
};

export const MobileTable = ({ rows }: { rows: Row[] }) => {
  const [activeRowIndex, setActiveRowIndex] = useState(0);

  const headerRows = rows[0].cell.slice(1);
  const dataRows = rows.slice(1);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxShadow: '4px 4px 16px 5px rgba(0, 0, 0, 0.09)',
        borderRadius: '12px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {headerRows.map((row, rowIdx) => {
          return (
            <DataCell
              key={row.id}
              content={row.content}
              onClick={() => setActiveRowIndex(rowIdx)}
              containerSx={getHeaderContainerStyles({
                isActive: activeRowIndex === rowIdx,
                isFirstCell: rowIdx === 0,
                isLastCell: rowIdx === headerRows.length - 1,
              })}
              cellSx={getHeaderCellStyles({
                rowIdx,
                isActive: activeRowIndex === rowIdx,
              })}
              as="button"
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {dataRows.map((col, colIdx) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }} key={col.id}>
              <DataCell
                content={col.cell[0].content}
                cellSx={getColHeadingStyles({
                  colIdx,
                  activeRowIndex,
                  totalColumns: dataRows.length,
                })}
                icon={col.cell[0].icon}
                containerSx={{
                  flexGrow: 1,
                  flexBasis: 0,
                  flexShrink: 0,
                  paddingLeft: '16px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                iconSx={{ marginTop: '1px', fill: '#0064FB' }}
              />
              <DataCell
                content={col.cell[activeRowIndex + 1].content}
                cellSx={getColContentStyles({
                  colIdx,
                  activeRowIndex,
                  totalColumns: dataRows.length,
                })}
                icon={
                  activeRowIndex === 0
                    ? { icon: 'tickColor' }
                    : col.cell[activeRowIndex + 1].icon
                }
                containerSx={{
                  flexGrow: 2,
                  flexBasis: 0,
                  flexShrink: 0,
                  px: '16px',
                }}
                iconSx={{
                  marginTop: '3px',
                  fill: activeRowIndex === 0 ? '#107EFF' : undefined,
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
