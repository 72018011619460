import React, { useMemo } from 'react';

import { Container, RichText } from '@sprinklr/shared-lib';
import { useBreakpointIndex } from '@theme-ui/match-media';

import { DesktopTable as DesktopTableV1 } from './components/desktopTable';
import { DesktopTable as DesktopTableV2 } from './components/desktopTableV2';
import { MobileTable as MobileTableV1 } from './components/mobileTable';
import { MobileTable as MobileTableV2 } from './components/mobileTableV2';
import {
  DesktopTable as DesktopTableV3,
  MobileTable as MobileTableV3,
} from './components/V3';

import { Title } from '@sprinklr/shared-lib';

import { Props } from './types';
import { Box, Flex, ThemeProvider, ThemeUICSSObject } from 'theme-ui';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import IconsLegend from './components/IconsLegend';
import { getColorMode } from './utils';

const TABLE_FOOTER_STYLES: ThemeUICSSObject = {
  p: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
  },
};

const MOBILE_VARIANT_VS_COMP = {
  V1: MobileTableV1,
  V2: MobileTableV2,
  V3: MobileTableV3,
};

const DESKTOP_VARIANT_VS_COMP = {
  V1: DesktopTableV1,
  V2: DesktopTableV2,
  V3: DesktopTableV3,
};

const TableTemplate = ({
  rows,
  title,
  variant,
  tableSubTitle,
  topMargin = 'MEDIUM',
  bottomMargin = 'MEDIUM',
  footerText,
  iconsLegend,
  colorVariant,
}: Props) => {
  const bpIndex = useBreakpointIndex({ defaultIndex: 3 });
  const [pt, pb] = useContainerPadding({
    top: topMargin,
    bottom: bottomMargin,
  });
  const isMobile = bpIndex === 0;

  const Comp = isMobile
    ? MOBILE_VARIANT_VS_COMP[variant ?? 'V1']
    : DESKTOP_VARIANT_VS_COMP[variant ?? 'V1'];

  const colors = useMemo(() => getColorMode(colorVariant), [colorVariant]);

  return (
    <ThemeProvider
      theme={{
        colors,
      }}
    >
      <Container
        containerSx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gridGap: ['20px', '32px', '72px'],
          pt,
          pb,
          mx: ['5px', '10px'],
        }}
        fullWidth={variant === 'V2' || variant === 'V3'}
        fullHeight={variant === 'V2' || variant === 'V3'}
      >
        <Flex
          sx={{ flexDirection: 'column', gap: '10px', textAlign: 'center' }}
        >
          <Title title={title} />
          {tableSubTitle && <RichText richTextObject={tableSubTitle} />}
        </Flex>

        {<Comp rows={rows as any[]} />}

        {iconsLegend && <IconsLegend icons={iconsLegend} />}

        <Box sx={TABLE_FOOTER_STYLES}>
          <RichText richTextObject={footerText} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TableTemplate;
