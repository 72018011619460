import React, { useState } from 'react';

import { Tooltip, RichText } from '@sprinklr/shared-lib';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import InfoIcon from '@sprinklr/shared-lib/assets/svgs/info.svg';

import {
  getHeaderCellStyles,
  getColHeadingStyles,
  getColContentStyles,
  getHeaderContainerStyles,
} from './styles';
import { Row } from '../../types';

const DataCell = ({
  content,
  cellSx,
  containerSx,
  onClick,
}: {
  content: Document;
  cellSx?: ThemeUIStyleObject;
  containerSx?: ThemeUIStyleObject;
  onClick?: () => void;
}) => {
  return (
    <Box sx={containerSx} onClick={onClick}>
      <Box sx={cellSx}>
        <RichText richTextObject={content} />
      </Box>
    </Box>
  );
};

export const MobileTable = ({ rows }: { rows: Row[] }) => {
  const [activeRowIndex, setActiveRowIndex] = useState(0);

  const headerRows = rows[0].cell.slice(1);
  const dataRows = rows.slice(1);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {headerRows.map((row, rowIdx) => {
          return (
            <DataCell
              content={row.content}
              onClick={() => setActiveRowIndex(rowIdx)}
              containerSx={getHeaderContainerStyles({
                rowIdx,
                isActive: activeRowIndex === rowIdx,
              })}
              cellSx={getHeaderCellStyles({
                rowIdx,
                isActive: activeRowIndex === rowIdx,
              })}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingY: '40px',
          paddingX: '52px',
          backgroundColor: activeRowIndex % 2 === 0 ? '#303840' : '#F1F1F1',
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
        }}
      >
        {dataRows.map((col, colIdx) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <DataCell
                content={col.cell[0].content}
                cellSx={getColHeadingStyles({
                  colIdx,
                  activeRowIndex,
                  totalColumns: dataRows.length,
                })}
              />
              <DataCell
                content={col.cell[activeRowIndex + 1].content}
                cellSx={getColContentStyles({
                  colIdx,
                  activeRowIndex,
                  totalColumns: dataRows.length,
                })}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
