import { ThemeUIStyleObject } from 'theme-ui';

export const getHeaderContainerStyles = ({
  isFirstCell,
  isLastCell,
  isActive,
}: {
  isFirstCell: boolean;
  isLastCell: boolean;
  isActive: boolean;
}): ThemeUIStyleObject => {
  return {
    cursor: 'pointer',
    display: 'flex',
    backgroundColor: isActive ? '#107eff0d' : '#fff',
    width: '50%',
    // zIndex: isActive ? 1 : 'auto',
    paddingY: '20px',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0px',
    ...(isFirstCell ? { borderTopLeftRadius: '12px' } : undefined),
    ...(isLastCell ? { borderTopRightRadius: '12px' } : undefined),
  };
};

export const getHeaderCellStyles = ({ rowIdx, isActive }: { rowIdx: number; isActive: boolean }): ThemeUIStyleObject => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
      color: isActive ? '#107EFF' : '#000000',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '17px',
      marginBottom: '0px',
      textTransform: 'capitalize',
      letterSpacing: '0.05em',
    },
  };
};

export const getColHeadingStyles = ({
  colIdx,
  activeRowIndex,
  totalColumns,
}: {
  colIdx: number;
  activeRowIndex: number;
  totalColumns: number;
}): ThemeUIStyleObject => {
  return {
    py: '12px',
    display: 'flex',
    p: {
      marginBottom: '0px',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
    },
  };
};

export const getColContentStyles = ({
  colIdx,
  activeRowIndex,
  totalColumns,
}: {
  colIdx: number;
  activeRowIndex: number;
  totalColumns: number;
}): ThemeUIStyleObject => {
  return {
    paddingTop: colIdx === 0 ? '16px' : '12px',
    paddingBottom: colIdx === totalColumns - 1 ? '20px' : '12px',
    display: 'flex',

    p: {
      marginBottom: '0px',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },
  };
};
