import React from 'react';

import { Box, ThemeUIStyleObject } from 'theme-ui';
import { ImageBlock, RichText } from '@sprinklr/shared-lib';
import { Icon } from '@sprinklr/shared-lib/components/icon';

import {
  getHeaderCellStyles,
  getRowCellStyles,
  getHeaderContainerStyles,
  getRowContainerStyles,
  getIconStyles,
} from './styles';

import { Row } from '../../types';

const DataCell = ({
  content,
  icon,
  image,
  cellSx,
  containerSx,
  iconSx,
}: Row['cell'][0] & {
  cellSx?: ThemeUIStyleObject;
  containerSx?: ThemeUIStyleObject;
  iconSx?: ThemeUIStyleObject;
}) => {
  return (
    <Box sx={containerSx}>
      <Box sx={cellSx}>
        <Icon
          iconName={icon?.icon}
          iconSx={{
            height: '14px',
            width: '14px',
            flexShrink: 0,
            marginRight: '10px',
            ...iconSx,
          }}
        />
        {image ? (
          <ImageBlock
            image={image}
            imageSx={{ height: 'auto', width: '100px', img: { width: 'auto' } }}
          />
        ) : (
          <RichText richTextObject={content} />
        )}
      </Box>
    </Box>
  );
};

export const DesktopTable = ({ rows }: { rows: Row[] }) => {
  const headerRows = rows[0];
  const dataRows = rows.slice(1);

  return (
    <Box
      sx={{
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        boxShadow: '4px 4px 30px 5px rgba(0, 0, 0, 0.09)',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {headerRows.cell.map((headerCell, headerCellIdx) => {
          return (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexBasis: 0,
                flexGrow: headerCellIdx === 0 ? 3.5 : 4,
                flexShrink: 0,
              }}
              key={headerCell.id}
            >
              <DataCell
                cellSx={getHeaderCellStyles({ columnIndex: headerCellIdx })}
                containerSx={getHeaderContainerStyles({
                  columnIndex: headerCellIdx,
                })}
                {...headerCell}
              />
              {headerCellIdx === 1 ? (
                <Box
                  sx={{
                    position: 'absolute',
                    height: '20px',
                    backgroundColor: '#107EFF',
                    borderTopRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    top: '-20px',
                    width: '100%',
                  }}
                />
              ) : null}
            </Box>
          );
        })}
      </Box>

      {dataRows.map((dataRow, dataRowIdx) => {
        return (
          <Box sx={{ display: 'flex' }}>
            {dataRow.cell.map((dataCell, dataCellIdx) => {
              return (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexBasis: 0,
                    flexGrow: dataCellIdx === 0 ? 3.5 : 4,
                    flexShrink: 0,
                  }}
                  key={dataCell.id}
                >
                  <DataCell
                    cellSx={getRowCellStyles({
                      columnIndex: dataCellIdx,
                      rowIndex: dataRowIdx,
                      totalRows: dataRows.length,
                    })}
                    containerSx={getRowContainerStyles({
                      columnIndex: dataCellIdx,
                      rowIndex: dataRowIdx,
                    })}
                    iconSx={getIconStyles({ columnIndex: dataCellIdx })}
                    {...dataCell}
                  />
                  {dataRowIdx === dataRows.length - 1 && dataCellIdx === 1 ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        height: '20px',
                        backgroundColor: '#107EFF',
                        borderBottomRightRadius: '12px',
                        borderBottomLeftRadius: '12px',
                        width: '100%',
                        bottom: '-20px',
                      }}
                    />
                  ) : null}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
