import { ThemeUIStyleObject } from 'theme-ui';

const BORDER = '2px solid #D7D7D7';

const getHeaderCellStyles = ({ cellIdx, totalCells }: { cellIdx: number; totalCells: number }): ThemeUIStyleObject => {
  const isFirstCell = cellIdx === 0;
  const isOddCol = cellIdx % 2 === 1;

  return {
    paddingLeft: isFirstCell ? '0px' : ['28px', null, '56px'],
    paddingRight: '36px',
    backgroundColor: isOddCol ? '#303840' : 'transparent',
    borderLeft: isFirstCell || isOddCol ? 'none' : BORDER,
    borderRight: isFirstCell || isOddCol ? 'none' : BORDER,
    borderTop: isFirstCell || isOddCol ? 'none' : BORDER,
    paddingTop: '40px',
    height: '100%',
    borderTopRightRadius: '30px',
    borderTopLeftRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    h6: {
      color: '#FAA21B',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '21px',
      marginBottom: '0px',
    },
  };
};

const getRowCellStyles = ({
  colIdx,
  rowIdx,
  totalColumns,
  totalRows,
}: {
  colIdx: number;
  rowIdx: number;
  totalRows: number;
  totalColumns: number;
}) => {
  const isOddCol = colIdx % 2 === 1;
  const isFirstCol = colIdx === 0;
  const isLastRow = rowIdx === totalRows - 1;
  let borderBottom;
  if (isFirstCol) {
    if (!isLastRow) {
      borderBottom = '1px solid #D5D5D5';
    }
  } else {
    if (isLastRow) {
      borderBottom = 'none';
    } else {
      borderBottom = '1px solid #D5D5D5';
    }
  }

  return {
    marginLeft: isFirstCol ? '0px' : ['28px', null, '56px'],
    marginRight: isFirstCol ? ['0ppx', null, '48px'] : ['24px', null, '36px'],
    borderBottom,
    borderBottomRightRadius: isLastRow ? '30px' : '0px',
    borderBottomLeftRadius: isLastRow ? '30px' : '0px',
    paddingBottom: isLastRow ? '46px' : '20px',
    paddingTop: '20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    h6: {
      color: isOddCol ? 'white' : 'black',
      fontSize: '17px',
      lineHeight: '21px',
      fontWeight: 400,
      marginBottom: '0px',
    },
    br: {
      display: 'block',
      content: "''",
      marginTop: '6px',
    },
  };
};

const getRowContainerStyles = ({
  colIdx,
  totalColumns,
  rowIdx,
  totalRows,
}: {
  colIdx: number;
  totalColumns: number;
  rowIdx: number;
  totalRows: number;
}) => {
  const isOddCol = colIdx % 2 === 1;
  const isLastCol = colIdx === totalColumns - 1;
  const isFirstCol = colIdx === 0;
  const isLastRow = rowIdx === totalRows - 1;

  return {
    backgroundColor: isOddCol ? '#303840' : 'transparent',
    borderLeft: isFirstCol || isOddCol ? 'none' : BORDER,
    borderRight: isFirstCol || isOddCol ? 'none' : BORDER,
    borderBottomRightRadius: isLastRow ? '30px' : '0px',
    borderBottomLeftRadius: isLastRow ? '30px' : '0px',
    borderBottom: isLastRow && !isFirstCol ? BORDER : 'none',
  };
};

export { getHeaderCellStyles, getRowCellStyles, getRowContainerStyles };
