import React from 'react';

import { Tooltip, RichText } from '@sprinklr/shared-lib';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import InfoIcon from '@sprinklr/shared-lib/assets/svgs/info.svg';

import {
  getHeaderCellStyles,
  getRowCellStyles,
  getRowContainerStyles,
} from './styles';
import { Row } from '../../types';

const DataCell = ({
  content,
  cellSx,
  containerSx,
  additionalInfo,
}: {
  containerSx?: ThemeUIStyleObject;
  cellSx: ThemeUIStyleObject;
  content: Document;
  additionalInfo?: string;
}) => (
  <Box sx={{ flexBasis: 0, flexGrow: 1 }}>
    <Box sx={{ ...containerSx, height: '100%' }}>
      <Box sx={cellSx}>
        <>
          <RichText richTextObject={content} />
          {additionalInfo ? (
            <Box
              sx={{
                flexShrink: 0,
                marginLeft: '8px',
                ':hover': { fill: 'primary' },
                display: 'flex',
                padding: '4px',
                cursor: 'pointer',
              }}
              data-tip={additionalInfo}
            >
              <InfoIcon />
            </Box>
          ) : null}
        </>
      </Box>
    </Box>
  </Box>
);

export const DesktopTable = ({ rows }: { rows: Row[] }) => {
  const headerRows = rows[0];
  const dataRows = rows.slice(1);

  const totalColumns = headerRows.cell.length;
  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', maxWidth: '1120px' }}
      >
        <>
          <Box sx={{ display: 'flex', gap: '28px' }}>
            {headerRows.cell.map((header, idx) => {
              return (
                <DataCell
                  cellSx={getHeaderCellStyles({
                    cellIdx: idx,
                    totalCells: totalColumns,
                  })}
                  content={header.content}
                  additionalInfo={header.additionalInfo}
                />
              );
            })}
          </Box>
          {dataRows.map((row, rowIdx) => {
            return (
              <Box sx={{ display: 'flex', gap: '28px' }}>
                {row.cell.map((cell, cellIdx) => {
                  return (
                    <DataCell
                      content={cell.content}
                      cellSx={getRowCellStyles({
                        colIdx: cellIdx,
                        rowIdx,
                        totalRows: dataRows.length,
                        totalColumns: row.cell.length,
                      })}
                      containerSx={getRowContainerStyles({
                        colIdx: cellIdx,
                        rowIdx,
                        totalRows: dataRows.length,
                        totalColumns: row.cell.length,
                      })}
                      additionalInfo={cell.additionalInfo}
                    />
                  );
                })}
              </Box>
            );
          })}
        </>
      </Box>
      <Tooltip place="top" />
    </>
  );
};
